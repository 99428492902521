import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';
import { CurrentUser } from '../../model/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenService: TokenService,
    private router: Router,
    private jwtHelperService: JwtHelperService,
  ) { }

  init() {
    var initPromise: Promise<boolean>[] = [];

    initPromise.push(new Promise((resolve, reject) => {
      setTimeout(() => { resolve(true); }, 1500);

    }));

    initPromise.push(new Promise(async (resolve, reject) => {
      console.log('into the initializer', this.authService.isAuthTokenExpired());
      console.log("token getter", this.authService.tokenGetter());
      if(this.authService.tokenGetter()) {
        if (this.authService.isAuthTokenExpired()) {
          this.authService.getNewToken().subscribe((result: any) => {
            console.log("result", result);
            // let token = this.authService.tokenGetter();
            // if (token != null) {
            //   let decodedToken = this.jwtHelperService.decodeToken(token);
            //   console.log("decoded Token", decodedToken);
            //   this.userService._currentUser.next(decodedToken);
            // }
            // resolve(true);
            this.userService.getUserAccessToken().subscribe({
              next: res => {
                let token = this.authService.tokenGetter();
                if (token != null) {
                  let decodedToken = this.jwtHelperService.decodeToken(token);
                  console.log("decoded Token", decodedToken);
                }
                let user: CurrentUser = res.data.message;
                this.userService._currentUser.next(user);
                console.log('result', user);
                resolve(true);
              },
              error: error => {
                this.authService.removeToken();
                console.log("location", location);
                if (location.pathname.includes('reset-password') || location.pathname.includes('forgot-password') || location.pathname.includes('login')) {
                  resolve(true);
                } else {
                  this.router.navigate(['/login']);
                  resolve(true);
                }
              },
              complete: () => {
                console.log('Request complete');
              }
            });
  
          }, error => {
            this.authService.removeToken();
            console.log("location", location);
            if (location.pathname.includes('reset-password') || location.pathname.includes('forgot-password') || location.pathname.includes('login')) {
              resolve(true);
            } else {
              this.router.navigate(['/login']);
              resolve(true);
            }
          })
        } else {
          this.userService.getUserAccessToken().subscribe({
            next: res => {
              let token = this.authService.tokenGetter();
              if (token != null) {
                let decodedToken = this.jwtHelperService.decodeToken(token);
                console.log("decoded Token", decodedToken);
              }
              let user: CurrentUser = res.data.message;
              this.userService._currentUser.next(user);
              console.log('result', user);
              resolve(true);
            },
            error: error => {
              this.authService.removeToken();
              console.log("location", location);
              if (location.pathname.includes('reset-password') || location.pathname.includes('forgot-password') || location.pathname.includes('login')) {
                resolve(true);
              } else {
                this.router.navigate(['/login']);
                resolve(true);
              }
              resolve(true);
            },
            complete: () => {
              console.log('Request complete');
            }
          });
        }
      } else {
        resolve(true);
      }
    }));

    return Promise.all(initPromise);
  }
}

export function initProviderFactory(provider: InitializerService) {
  return () => provider.init();
}