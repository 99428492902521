import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, empty, Observable, of } from 'rxjs';
import { $REST_URI } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CurrentUser } from '../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: Observable<CurrentUser>;
  _currentUser: BehaviorSubject<CurrentUser>;

  userRoleClaims: Observable<any>;
  _userRoleClaims: BehaviorSubject<any>;
  
  mobileScreen: Observable<boolean>;
  _mobileScreen: BehaviorSubject<boolean>;

  screenResolution: Observable<any>;
  _screenResolution: BehaviorSubject<any>;

  private _empFormData = new BehaviorSubject(FormGroup);
  empFormData = this._empFormData.asObservable();

  // tempData: CurrentUser = new CurrentUser();

  tempData: CurrentUser = {
    _id: "",
    contactNumber: "",
    email: "",
    displayName: "",
    firstName: "",
    gender: "",
    isEmailVerified: false,
    lastName: "",
    role: [],
    status: ""
  }

  constructor(
    private http: HttpClient,
    // private authService: AuthService
  ) {
    this._mobileScreen = new BehaviorSubject<boolean>(false);
    this.mobileScreen = this._mobileScreen.asObservable();
    this._screenResolution = new BehaviorSubject<any>({});
    this.screenResolution = this._screenResolution.asObservable();
    this.setUser(this.tempData);
    this._userRoleClaims = new BehaviorSubject<any>('');
    this.userRoleClaims = this._userRoleClaims.asObservable();
  }
  setUser(data: any) {
    this._currentUser = new BehaviorSubject<CurrentUser>(data);
    this.currentUser = this._currentUser.asObservable();
  }

  forgotPassword(email: string) {
    return this.http.post(`${$REST_URI}/auth/forgotPassword`, email);
  }

  resetPassword(data: any, token: string) {
    let header = new HttpHeaders();
    header = header.append('x-auth-token', token);

    return this.http.put(`${$REST_URI}/auth/resetPassword`, data, { headers: header });
  }

  getUserAccessToken() {
    // let data = { refreshToken: this.authService.tokenGetter() };
    return this.http.get<any>(`${$REST_URI}/api/admin`);
  }

  getAdminUsersList() {
    return this.http.get<any>(`${$REST_URI}/api/admin/list`);
  }

  inviteUser(data: any) {
    return this.http.post<any>(`${$REST_URI}/api/admin/invite`, data);
  }

  reActivateUser(data: any) {
    return this.http.put<any>(`${$REST_URI}/api/admin/reactivate`, data);
  }

  deActivateUser(data: any) {
    return this.http.put<any>(`${$REST_URI}/api/admin/deactivate`, data);
  }
}
