export const $iconPath: string = 'assets/icons'
export const $imagePath: string = 'assets/images'
export const $icons: string[] = [
    'dashboard',
    'noun_cross',
    'noun_cancel',
    'noun_tick',
    'noun_profile',
    'noun_log_out',
    'gps',
    'next',
    'prev',
    'lq_fastag_icon',
    'yb_fastag_icon',
    'gps_icon',
    'card_icon',
    'users',
    'rupee',
    'users'
]