import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, empty, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarService } from '../services/snackbar/snack-bar.service';
import { SnackBarLoaderComponent } from '../shared/app-common/snack-bar-loader/snack-bar-loader.component';
import { Router } from '@angular/router';
import { retryWhen, concatMap, delay, tap, switchMap, catchError, finalize, filter, take } from 'rxjs/operators';
import { $APP_VALUE } from '../values/app.value';

@Injectable()
export class HttpRequestResponceInterceptor implements HttpInterceptor {

  retryLoader: MatSnackBarRef<SnackBarLoaderComponent>;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isRefreshingToken = false;

  constructor(private authService: AuthService, private notify: SnackBarService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    if (this.checkRouteAuth(request)) {
      if (this.checkCancelAuth(request)) {
        return next.handle(request);
      } else {
        return this.interceptNext(request, next);
      }
    } else {
      // console.log(this.authService.isAuthTokenExpired());
      if (this.authService.tokenGetter() !== null && this.authService.isAuthTokenExpired()) {
        return this.handleUnauthorized(request, next);
      } else {
        if (this.authService.tokenGetter()) {
          let clone = request.clone({
            setHeaders: {
              // 'x-auth-token': this.authService.tokenGetter()
              'Authorization': `Bearer ${this.authService.tokenGetter()}`,
            }
          });
          return this.interceptNext(clone, next);
        }

        return this.handleUnauthorized(request, next);
      }
    }
  }

  interceptNext(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error) => {
          console.log("error", error);
          if (error.status === 0) { // && count < 10
            if (this.retryLoader) {
              if (!this.retryLoader.instance.loading) {
                this.retryLoader = this.notify.retryLoader();
              }
            } else this.retryLoader = this.notify.retryLoader();
            return of(error.status);
          }
          else if (error.status === 500) {
            error['errorCode'] = error.error.code;
          }
          else if (error.status === 401) {
            if (location.pathname.includes('reset-password') || location.pathname.includes('forgot-password') || location.pathname.includes('login')) {
              this.authService.removeToken();
            } else {
              this.authService.removeToken();
              this.router.navigate(['/login']);
            }
            // this.notify.error('Server Error, Please try again later');
          }
          return throwError(error);
        }),
        delay(5000)
      )),
      tap((res) => {
        if (res instanceof HttpResponse && this.retryLoader) {
          this.retryLoader.dismiss();
          // this.retryLoader = null;
          // this.notify.info('Back Online', 'Ok');
        }
      }),
    )
  }

  handleUnauthorized(req: any, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.refreshTokenGetter() === null) {
      if (location.pathname.includes("admin")) {
        console.log('session 1');
        this.authService.removeToken();
        this.router.navigate(['/login']);

        return throwError({ exception: true, error: 'Session timeout, login again' });
      }
    }
    if (this.authService.refreshTokenGetter() !== null) {
      if (this.isRefreshingToken) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this.tokenSubject
          .pipe(
            filter(token => token != null)
            , take(1)
            , switchMap(token => {
              let clone = this.addToken(req, token);
              return this.interceptNext(clone, next);
            })
          );
      }
      else {
        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.isRefreshingToken = true;
        this.tokenSubject.next(null);
        // get a new token via authService.getNewToken
        return this.authService.getNewToken()
          .pipe(switchMap((newToken: any) => {
            // did we get a new token retry previous request
            if (newToken.accessToken && newToken.refreshToken) {
              this.tokenSubject.next(newToken.accessToken);
              this.authService.setNewToken(newToken.accessToken, newToken.refreshToken);
              let clone = this.addToken(req, newToken.accessToken);
              return this.interceptNext(clone, next);
            }
            // If we don't get a new token, we are in trouble so logout.
            this.authService.removeToken();
            this.router.navigate(['/login']);
            console.log('session 2');
            return throwError({ exception: true, error: 'Session timeout, login again' });
          })
            , catchError(error => {
              if (this.authService.refreshTokenGetter() !== null) {
                return throwError(error);
              }
              // If there is an exception calling 'refreshToken', bad news so logout.
              this.authService.removeToken();
              this.router.navigate(['/login']);
              console.log('session 3');
              return throwError({ exception: true, error: 'Session timeout, login again' });
            })
            , finalize(() => {
              this.isRefreshingToken = false;
            })
          );
      }
    }
    else {
      return this.tokenSubject
        .pipe(
          filter(token => token != null)
          , take(1)
          , switchMap(token => {
            let clone = this.addToken(req, token);
            return this.interceptNext(clone, next);
          })
        );
    }
  }

  addToken(request: any, token: unknown) {
    // Get access token from Local Storage
    const accessToken = token;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({

      setHeaders: {
        // 'x-auth-token': token
        'Authorization': `Bearer ${this.authService.tokenGetter()}`,
      }
    });
  }

  checkCancelAuth(req: HttpRequest<any>) {
    // console.log(req.url);
    let urls = $APP_VALUE.canIgnoreListUrls;
    let index = 0;
    // console.log('canIgnoreListUrls', urls);

    urls.forEach(ele => {
      if (req.url.match(ele)) {
        index = index + 1;
      }
    });
    // console.log("index", index);
    if (index > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkRouteAuth(req: HttpRequest<any>) {
    let urls = $APP_VALUE.blackListUrls;
    let index = 0;

    urls.forEach(ele => {
      if ((req.url.match(ele) || req.url === ele)) {
        index = index + 1;
      }
    });
    if (index > 0) {
      return true;
    } else {
      return false;
    }
  }
}
