import { Component, OnInit } from '@angular/core';
import { IconRegisterService } from './core/services/icon-register/icon-register.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'axlerate-admin';

  constructor(
    private iconRegister: IconRegisterService,
    private swUpdate: SwUpdate,
  ) {
    if (swUpdate.isEnabled) {
      // this.swUpdate.checkForUpdate();
      try {
        const updateFound = swUpdate.checkForUpdate();
        if(updateFound) {
          swUpdate.versionUpdates
          .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
          .subscribe(evt => {
            if (confirm('Update Available, Do you want to reload?')) {
              // Reload the page to update to the latest version.
              document.location.reload();
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async ngOnInit() {
    this.iconRegister.registerIcons(); 
  }
}
