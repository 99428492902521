import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $REST_URI } from 'src/environments/environment';
import { loginRequest } from '../../model/user.model';
import { map } from 'rxjs';
import { TokenService } from '../token/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../user/user.service';

interface TokenResponse {
  accessToken: string, refreshToken: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private jwt: JwtHelperService,
    private userService: UserService
  ) { }

  login(data: loginRequest) {
    console.log("login data", data);
    return this.http.post<HttpResponse<any>>(`${$REST_URI}/api/admin/login`,
      data, { observe: 'response' }).pipe(
        map(response => {
          console.log(response.headers);
          response.headers.keys(); // all header names
          response.body // response contentF

          if (response.headers) {
            console.log(response.headers.get('x-auth-token'));
            this.tokenService.saveToken(response.headers.get('x-auth-token'));
            this.tokenService.saveRefreshToken(response.headers.get('x-auth-refresh-token'));
          }

          return response.body;
        })
      )

    // return this.http.post<any>(`${$REST_URI}/api/admin/login`, data);
  }

  forgotPassword(data: any) {
    console.log("forgot password", data);
    return this.http.post<any>(`${$REST_URI}/api/admin/forgot-password`, data);
  }

  resetPassword(data: any, token: string) {
    let header = new HttpHeaders();
    header = header.append('Authorization', `Bearer ${token}`);

    return this.http.post<any>(`${$REST_URI}/api/admin/reset-password`, data, { headers: header });
  }

  logout() {
    let data = {};
    return this.http.patch<any>(`${$REST_URI}/api/admin/logout`, data);
  }

  tokenGetter() {
    if (localStorage.getItem('auth-token') === "null" || localStorage.getItem('auth-token') === null) {
      localStorage.removeItem('auth-token');
    } else if (localStorage.getItem('auth-token') === "undefined") {
      localStorage.removeItem('auth-token');
    }
    return localStorage.getItem('auth-token');
  }

  refreshTokenGetter() {
    return localStorage.getItem('refresh-token');
  }

  isAuthTokenExpired(): boolean {
    let accessToken = this.tokenGetter();
    return accessToken ? this.jwt.isTokenExpired(accessToken) : true;
  }

  // isRefreshTokenExpired(): boolean {
  //   let refreshToken = this.refreshTokenGetter();
  //   return refreshToken ? this.jwt.isTokenExpired(refreshToken) : true;
  // }

  getNewToken() {
    // let refreshToken = this.refreshTokenGetter();
    let url = `${$REST_URI}/api/admin/accessToken`;

    let header = new HttpHeaders();
    header = header.append('Authorization', `Bearer ${this.refreshTokenGetter()}`);

    let data = {};
    return this.http.get<HttpResponse<any>>(url, { headers: header, observe: 'response' }).pipe(
        map(response => {
          console.log(response);
          response.headers.keys(); // all header names
          response.body // response contentF
          let accessToken;
          let refreshToken;
          if (response.headers) {
            console.log(response.headers.get('x-auth-token'));

            accessToken = response.headers.get('x-auth-token');
            refreshToken = response.headers.get('x-auth-refresh-token');

            this.tokenService.saveToken(response.headers.get('x-auth-token'));
            this.tokenService.saveRefreshToken(response.headers.get('x-auth-refresh-token'));
            this.setNewToken(accessToken, refreshToken);
          }
          let data = {accessToken, refreshToken};
          return data;
        })
      )
  }

  setNewToken(accessToken: string, refreshToken: string) {
    console.log('setNewToken()')
    localStorage.setItem('auth-token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
  }

  removeToken() {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('refresh-token');
    this.userService._currentUser.next(null);
  }

  activateUser(data: any, token: string) {
    let header = new HttpHeaders();
    header = header.append('Authorization', `Bearer ${token}`);
    
    return this.http.put<any>(`${$REST_URI}/api/admin/activate`, data, { headers: header });
  }
}
