import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state) => {
  return new Promise((resolve, reject) => {
    const userService = inject(UserService);
    let router = inject(Router);
    userService.currentUser.subscribe(user => {
      console.log("USer", user);
      console.log("route",route)
      let page = route.data['page'];
      if (user?.email && user?._id) {
        if(page !== 'admin') {
          router.navigate(['/admin/app/dashboard']);
          resolve(true);
        } else {
          resolve(true);
        }
      } else {
        if(page !== 'admin') {
          resolve(true);
        } else {
          router.navigate(['/login']);
          reject();
        }
      }
    })
    // resolve(true);
  })
};