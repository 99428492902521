import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth/auth.guard';
import { UserService } from './core/services/user/user.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule), canActivate: [authGuard], data: {page: 'account'} },
  { path: 'admin', loadChildren: () => import('./modules/main-app/main-app.module').then(m => m.MainAppModule), canActivate: [authGuard], data: {page: 'admin'} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(userService: UserService) {}

}
