import { $REST_URI } from "src/environments/environment";

export const $APP_VALUE = {
    canIgnoreListUrls: [
        'assets/images',
        'assets/icons',
        'https://axle-tag-dev.s3.ap-south-1.amazonaws.com',
    ],
    blackListUrls: [
        `${$REST_URI}/api/admin/login`,
        `${$REST_URI}/api/admin/forgot-password`,
        `${$REST_URI}/api/admin/reset-password`,
        `${$REST_URI}/api/admin/accessToken`,
        `${$REST_URI}/api/admin/activate`,
        'assets/images',
        'assets/icons/info.svg',
        `${$REST_URI}/auth`,
        'https://axle-tag-dev.s3.ap-south-1.amazonaws.com',
    ],

    paginatorOptions: [20, 40, 100],

    colors: {
        // ybTag: "#1EA5FC",
        // lqTag: "#11AB77",
        // ppi: "#4BAD37",
        // gps: "#FC4E12"
        ybTag: "#1E538F",
        lqTag: "#11AB77",
        ppi: "#FECB4D",
        gps: "#1EA5FC"
    }

};